import React from "react";

import { ChartDataFieldIconShape } from "@/js/types/charts";

// This component renders ChartDataFieldIconShapes with the color provided
const ChartDataFieldIcon = ({
  color,
  iconShape = "circle-filled",
}: {
  color: string;
  iconShape?: ChartDataFieldIconShape;
}) => {
  const commonProps = { cx: "7", cy: "7", r: "6" };

  switch (iconShape) {
    case "circle-outline":
      return (
        <svg className="legend-icon" height="14" width="14">
          <circle {...commonProps} fill="none" stroke={color} strokeWidth="2" />
        </svg>
      );
    case "circle-outline-dashed":
      return (
        <svg className="legend-icon" height="14" width="14">
          <circle
            {...commonProps}
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeDasharray="3,3"
          />
        </svg>
      );
    case "circle-filled":
    default:
      return (
        <svg className="legend-icon" height="14" width="14">
          <circle {...commonProps} fill={color} stroke="none" />
        </svg>
      );
  }
};

export default ChartDataFieldIcon;
