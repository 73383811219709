import type { ReactNode } from "react";

import { classnames } from "@/js/utils/cambio";

interface MetricProps {
  size?: "large" | "default";
  children: ReactNode;
}

/**
 * We can probably add nice formatting options in this component in the future, but for now this
 * will just be for consistent styling per our styleguide.
 */
export default function MetricValue({ size = "default", children }: MetricProps) {
  return <em className={classnames("MetricValue", { [size]: size !== "default" })}>{children}</em>;
}
