import type { ReactNode } from "react";

import { BreakdownEntry, ChartDataField } from "@/js/types/charts";
import { getNestedValue } from "@/js/utils/cambio";

import ChartDataFieldIcon from "../ChartDataFieldIcon";
import ChartLabel from "../ChartLabel";

/**
 * Custom Chart Tooltip component that displays the data for a chart item when hovered over.
 * It is passed into the recharts Tooltip component using the content prop to display instead
 * of the default recharts tooltip.
 *
 * We support a few different tooltip versions in line with our design system:
 *
 * * a simple icon + value
 * * an icon and name with a value
 * * a list of icon + values with optional name
 * * an entry with a breakdown, which is a list of entries that make up the parent entry. Multiple
 *   data entry breakdowns is supported with a `breakdownKey` in the ChartDataField, but practically
 *   this will only ever be present on a single data entry. Default breakdown key is "breakdown".
 */
const ChartTooltip = ({
  data,
  labelKey,
  chartDataFields,
  nameFormatter = (name) => name,
  labelFormatter = (label) => label,
  valueFormatter = (value) => String(value),
}: {
  data: Record<string, any>;
  labelKey: string;
  chartDataFields: ChartDataField[];
  nameFormatter?: (name: string) => string;
  labelFormatter?: (label: string) => string;
  valueFormatter?: (value: number, record: Record<string, any>) => ReactNode;
}) => {
  /**
   * The value shown for a tooltip can be in a couple places:
   *
   * 1. Next to the icon, if there is no entry name shown
   * 2. In a sibling grid element next to the icon/name combo
   */
  const getValue = (data: Record<string, any>, fieldKey: string) => (
    <span className="tooltip-entry-value">
      {valueFormatter(getNestedValue(data, fieldKey), data)}
    </span>
  );

  return (
    <div className="ChartTooltip">
      <h6>{labelFormatter(`${getNestedValue(data ? data : {}, labelKey)}`)}</h6>
      <ul className="chart-tooltip-grid">
        {data &&
          chartDataFields.map((field, index) => {
            const breakdownKey = field.breakdownKey || "breakdown";
            // For charts that have single entries on a tooltip, a breakdown shows what the entry comprises of
            const entryHasBreakdown = !!data?.[breakdownKey]?.length;

            return (
              <li key={index} className="chart-tooltip-item">
                <span>
                  {field.iconShape !== null ?
                    <ChartDataFieldIcon iconShape={field.iconShape} color={field.color} />
                  : null}
                  {/** if there's no name, show the value here directly next to the icon */}
                  {field.name || entryHasBreakdown ?
                    <ChartLabel>
                      {entryHasBreakdown ? "Total" : nameFormatter(field.name)}
                    </ChartLabel>
                  : getValue(data, field.key)}
                </span>
                {/** if there is a name, show the value here in a separate grid element */}
                {field.name || entryHasBreakdown ? getValue(data, field.key) : null}
                {/** for entries with breakdowns, the breakdown does not have an icon, just name/value */}
                {entryHasBreakdown ?
                  <ul className="breakdown-entries">
                    {data[breakdownKey].map((entry: BreakdownEntry) => (
                      <li key={entry.name}>
                        <span>
                          {entry.color ?
                            <ChartDataFieldIcon iconShape={field.iconShape} color={entry.color} />
                          : null}
                          <ChartLabel>{entry.name}</ChartLabel>
                        </span>
                        {getValue(entry, "value")}
                      </li>
                    ))}
                  </ul>
                : null}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default ChartTooltip;
