import dayjs from "dayjs";

import { getNestedValue } from "./cambio";

type headerObject = {
  title: string;
  key?: string;
  value?: string;
  processValue?: (value: any) => string;
};

export function convertToCsvAndDownload(headers: headerObject[], data: any[], csvName: string) {
  downloadCSV(convertToCSV(headers, data), csvName + ".csv");
}

export function generateCSVTitle(
  baseName: string,
  orgOrPropertyName: string,
  dateRange: [string, string],
) {
  return (
    `${orgOrPropertyName} ${baseName} ` +
    dateRange.map((day) => dayjs(day).format("MMM DD, YYYY")).join("-")
  );
}

export function convertToCSV(headers: headerObject[], data: any[]): string {
  // Extracting header titles for the CSV
  const headerTitles = headers.map((header) => header.title);

  // Converting data to CSV format based on the provided headers
  const rows = data.map((row) =>
    headers
      .map((header) => {
        const cellValue =
          header.value ? header.value
          : header.processValue ?
            header.processValue(header.key ? getNestedValue(row, header.key) : row)
          : getNestedValue(row, header.key);

        return typeof cellValue === "string" && cellValue.includes(",") ?
            `"${cellValue}"`
          : cellValue;
      })
      .join(","),
  );

  // Joining the headers and rows to form the CSV
  return [headerTitles.join(","), ...rows].join("\r\n");
}

export function downloadCSV(data: string, filename: string): void {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");

  a.style.display = "none";
  a.href = url;
  a.download = filename;

  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}
