// @ts-ignore

import { getNestedValue } from "./cambio";

/* eslint-disable */
const createTrend = require("trendline");

export const calculateTrendLineData: any = (
  chartData: Record<string, any>[],
  xDataKey: string,
  yDataKey: string,
) => {
  // Use map to create indexedChartData
  const indexedChartData = chartData.map((item, index) => ({
    index: index,
    data: getNestedValue(item, yDataKey),
  }));

  const trend = createTrend(indexedChartData, "index", "data");

  if (indexedChartData.length > 1) {
    const trendData = [
      { x: chartData[0]?.[xDataKey], y: trend.calcY(0) },
      {
        x: chartData[indexedChartData.length - 1]?.[xDataKey],
        y: trend.calcY(indexedChartData.length - 1),
      },
    ];
    return trendData;
  }

  return [];
};
