import IconButton from "../IconButton";
import { track } from "@/js/services/mixpanel";
import { MouseEvent } from "react";
import { useRouter } from "next/router";

/**
 * Used often enough to warrant its own component. Won't show unless feature enabled, and also
 * tracks clicks to mixpanel.
 */
export const CardDownloadButton = ({
  onClick,
  disabled,
  name,
}: {
  onClick: (evt: MouseEvent) => void;
  disabled?: boolean;
  name?: string;
}) => {
  const router = useRouter();

  return (
    <IconButton
      disabled={disabled}
      icon="download"
      onClick={(evt) => {
        track("CSV Download Clicked", {
          name: name ? `${name} Card` : undefined,
          type: router.query.space_token ? "property" : "aggregate",
        });
        onClick(evt);
      }}
    />
  );
};
