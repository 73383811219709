import { useProjectsState } from "@/state/projectsState";
import omit from "lodash/omit";
import { useRouter } from "next/router";
import { createContext, memo, useEffect } from "react";

import ErrorBoundary from "@/components/ErrorBoundary";

import usePageTitle from "@/js/hooks/usePageTitle";
import { MixpanelTracking } from "@/js/services/mixpanel";

type PageContextStateValues = Partial<ReturnType<typeof useProjectsState>>;

export type PageContextValues = Partial<PageContextStateValues>;

export const PageContext = createContext({} as PageContextValues);

const CambioPage = memo(
  ({
    children,
    title = "",
  }: {
    children: any;
    /** This will be used as the document title */
    title?: string;
  }) => {
    const router = useRouter();

    //Define page states here
    const projectState = useProjectsState();

    useEffect(() => {
      MixpanelTracking.getInstance().pageViewed(title);
    }, []);

    usePageTitle(title);

    useEffect(() => {
      // TODO (noah): this omit is a quick hack because we want to be able to assemble a
      // query without overriding any exisitng query params. The problem is that NextJS
      // doesn't distinguish between path and query params, so without this omit we would
      // get path params as query params, as well.
      let query = { ...omit(router.query, "organization_token", "space_token", "tab") };

      if (Object.keys(query).length) {
        router.replace({ pathname: router.asPath.split("?")[0], query });
      }
    }, []);

    return (
      <PageContext.Provider value={projectState}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </PageContext.Provider>
    );
  },
);

export default CambioPage;
