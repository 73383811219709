import React from "react";

import TextOverflow from "@/components/TextOverflow";

import { ChartDataFieldIconShape } from "@/js/types/charts";
import { classnames } from "@/js/utils/cambio";

import ChartDataFieldIcon from "../ChartDataFieldIcon";

export type ChartLegendItem = {
  color: string;
  name: string;
  iconShape?: ChartDataFieldIconShape;
  value?: string;
};

const ChartLegend = ({
  chartLegendItems,
  vertical = false,
  showValue = false,
}: {
  chartLegendItems: ChartLegendItem[];
  vertical?: boolean;
  showValue?: boolean;
}) => {
  return (
    <ul className={classnames("ChartLegend", { vertical: vertical })}>
      {chartLegendItems?.map((chartLegendItem, index) => (
        <li key={`item-${index}`}>
          <ChartDataFieldIcon color={chartLegendItem.color} iconShape={chartLegendItem.iconShape} />

          {showValue ?
            <>
              <h5>
                <TextOverflow>{chartLegendItem.name}</TextOverflow>
              </h5>
              <p>{chartLegendItem?.value || ""}</p>
            </>
          : <span>{chartLegendItem.name}</span>}
        </li>
      ))}
    </ul>
  );
};

export default ChartLegend;
